<template>
  <div>
    <div class="container-box">
      <b-row class="no-gutters px-3 px-sm-0">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            จัดการภาพ Landing page
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white p-3">
        <b-row>
          <b-col md="6">
            <UploadFile
              textFloat="ภาพสำหรับคอมพิวเตอร์"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="image"
              name="thumbnailDesktop"
              :fileName="fileName.desktop"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg"
              isRequired
              v-on:onFileChange="(val) => onImageChange(val, 'desktop')"
              v-on:delete="deleteImage('desktop')"
              :v="$v.form.desktop"
            />

            <div
              class="preview-box b-contain landing-img"
              v-bind:style="{ 'background-image': 'url(' + form.desktop + ')' }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingImage.desktop"
              />
            </div>
          </b-col>
          <b-col md="6">
            <UploadFile
              textFloat="ภาพสำหรับมือถือ"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="image"
              name="thumbnailMobile"
              :fileName="fileName.mobile"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg"
              isRequired
              v-on:onFileChange="(val) => onImageChange(val, 'mobile')"
              v-on:delete="deleteImage('mobile')"
              :v="$v.form.mobile"
            />

            <div
              class="preview-box b-contain landing-img"
              v-bind:style="{ 'background-image': 'url(' + form.mobile + ')' }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingImage.mobile"
              />
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col md="6"></b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              @click="checkForm(1)"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
          </b-col>
        </b-row>
      </div>
      <ModalAlert ref="modalAlert" :text="modalMessage" />
      <ModalAlertError ref="modalAlertError" :text="modalMessage" />
      <ModalLoading ref="modalLoading" :hasClose="false" />
    </div>
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Logo",
  components: {
    UploadFile,
    ModalAlert,
    ModalAlertError,
    ModalLoading
  },
  data() {
    return {
      isLoadingData: false,
      isDisable: false,
      modalMessage: "",
      form: {
        desktop: "",
        mobile: ""
      },
      isLoadingImage:{
        desktop: false,
        mobile: false
      },
      fileName: {
        desktop: "",
        mobile: ""
      }
    };
  },
  validations: {
    form: {  
      desktop: { required }, 
      mobile: { required }, 
    },
  },
  created: async function () {
    await this.getDatas();
  },
  methods: {
    getDatas: async function () {
      this.isLoadingData = true;

      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/setting/GetLandingPage`,
        null,
        this.$headers,
        null
      );
      this.form = {...resData.detail};
      this.fileName = {...resData.detail};
      this.$isLoading = true;
    },
    onImageChange(img, key) {
      this.fileName[key] = img.name;
      this.isLoadingImage[key] = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.form[key] = reader.result;
        this.isLoadingImage[key] = false;
      };
    },
    deleteImage(key) {
      this.fileName[key] = "";
      this.form[key] = "";
    },
    checkForm: async function (flag) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/setting/saveLandingPage`,
        null,
        this.$headers,
        this.form
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.form = {...data.detail};
        this.fileName = {...data.detail};
      } else {
        this.modalMessage = data.detail[0];
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
.landing-img {
  width: 100%;
  padding-bottom: 100%;
  background-size: contain !important;
}
</style>